<template>
  <b-row>
    <b-col cols="12">
      <b-card-code>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Procurar:</label>
              <b-form-input
                v-model="searchTerm"
                placeholder=" Procurar"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <b-form-group>
            <div class="d-flex align-items-center ml-2">
              <b-button variant="primary" v-b-modal.modalnewUser>
                <feather-icon icon="UserPlusIcon" class="mr-50 clique" />
              </b-button>
            </div>
          </b-form-group>
          <b-modal
            id="modalnewUser"
            modal-class="modal-primary"
            centered
            title="Novo Usuário"
            ok-title="Cadastrar"
            @ok="saveNewUser()"
            cancel-title="Cancelar"
          >
            <!--   NOME -->
            <b-form-group label="Nome" label-for="nome" label-cols-md="4">
              <b-form-input
                id="nome"
                placeholder="Nome"
                v-model="newUser.name"
              />
            </b-form-group>
            <!--   e-mail -->
            <b-form-group label="E-mail" label-for="email" label-cols-md="4">
              <b-form-input
                id="email"
                placeholder="E-mail"
                v-model="newUser.email"
              />
            </b-form-group>
            <!--   access -->
            <b-form-group label="Acesso" label-for="acesso" label-cols-md="4">
              <b-form-input
                id="acesso"
                placeholder="Acesso"
                v-model="newUser.access"
              />
            </b-form-group>
          </b-modal>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="users"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <!-- Slot: Table Row -->
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'actions'" class="text-nowrap">
              <span @click="edit(props.row._id)">
                <feather-icon icon="Edit2Icon" class="mr-50 clique" />
                <span class="clique">Editar</span>
              </span>
            </span>
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="OnOrOffVariant(props.row)">
                {{ OnOrOff(props.row) }}
              </b-badge>
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Máximo por página </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  Total de <b>{{ props.total }}</b> Cadastros
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton,
  BBadge,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { VueGoodTable } from "vue-good-table";

import store from "@/store/index";

import axios from "axios";
import { baseApiUrl, globalPassword } from "@/global";

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    BBadge,
  },
  data() {
    return {
      searchTerm: "",
      columns: [
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "E-mail",
          field: "email",
        },
        {
          label: "Acesso",
          field: "access",
        },
        {
          label: "Ações",
          field: "actions",
        },
      ],
      users: [],
      newUser: {
        name: "",
        email: "",
        access: "",
      },
      accessOptions: [],

      pageLength: 10,
      stateUser: [],
    };
  },
  methods: {
    async getState() {
      await axios.get(`${baseApiUrl}/status`).then((res) => {
        this.stateUser = res.data.users;
      });
    },
    getUsers() {
      axios.get(`${baseApiUrl}/users`).then((res) => {
        if (res.data.success) {
          this.users = res.data.data;
        }
      });
    },
    edit(id) {
      this.$router.push({ path: `/usuarios/${id}` });
    },
    saveNewUser() {
      if (
        this.newUser.name.length &&
        this.newUser.email.length &&
        this.newUser.access.length
      ) {
        this.newUser.password = globalPassword;
        this.newUser.level = 1;

        axios.post(`${baseApiUrl}/users`, this.newUser).then((res) => {
          if (res.data.success) {
            this.$bvToast.toast("Cadastro realizado com sucesso!", {
              title: "Sucesso!",
              variant: "primary",
              solid: true,
            });
            this.getUsers();
          }
        });
      }
    },
    OnOrOffVariant(state) {
      for (let i = 0; this.stateUser.length > i; i++) {
          if (state._id === this.stateUser[i].userId) {
            return "primary"
          }
        }
        return "danger"
    },
    OnOrOff(state) {
      for (let i = 0; this.stateUser.length > i; i++) {
          if (state._id === this.stateUser[i].userId) {
            return "ON"
          }
        }
        return "OFF"
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getUsers();
    this.getState()
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.clique:hover {
  cursor: pointer;
  transform: scale(1.5);
  transition: all 0.5s;
}
</style>